import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import $ from "jquery";

export interface User {
	name: string;
	surname: string;
	email: string;
	password: string;
	api_token: string;
}

export interface UserAuthInfo {
	errors: unknown;
	user: User;
	isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
	errors = {};
	user = {} as User;
	globalInformations = null;
	isAuthenticated = !!JwtService.getToken();

	/**
	 * Get current user object
	 * @returns User
	 */
	get currentUser(): User {
		return this.user;
	}

	/**
	 * Verify user authentication
	 * @returns boolean
	 */
	get isUserAuthenticated(): boolean {
		return this.isAuthenticated;
	}

	/**
	 * Verify user authentication
	 * @returns boolean
	 */
	get currentGlobalInformations() {
		return this.globalInformations;
	}

	/**
	 * Get authentification errors
	 * @returns array
	 */
	get getErrors() {
		return this.errors;
	}

	@Mutation
	[Mutations.SET_ERROR](error) {
		this.errors = { ...error };
	}

	@Mutation
	[Mutations.SET_AUTH](user) {
		this.isAuthenticated = true;
		this.user = user;
		this.errors = {};
		JwtService.saveToken(user.api_token);
		ApiService.setHeader();
	}

	@Mutation
	[Mutations.SET_GLOBAL_INFORMATIONS](globalInformations) {
		this.globalInformations = globalInformations;
	}

	@Mutation
	[Mutations.SET_USER](user) {
		this.user = user;
	}

	@Mutation
	[Mutations.SET_PASSWORD](password) {
		this.user.password = password;
	}

	@Mutation
	[Mutations.PURGE_AUTH]() {
		this.isAuthenticated = false;
		this.user = {} as User;
		this.errors = [];
		this.globalInformations = null;
		JwtService.destroyToken();
	}

	@Action
	[Actions.LOGIN](credentials) {
		return ApiService.post("login", credentials)
			.then(({ data }) => {
				this.context.commit(Mutations.SET_AUTH, data);
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, response.data.errors);
			});
	}

	@Action
	[Actions.LOGOUT]() {
		this.context.commit(Mutations.PURGE_AUTH);
	}

	@Action
	[Actions.REGISTER](credentials) {
		return ApiService.post("register", credentials)
			.then(({ data }) => {
				this.context.commit(Mutations.SET_AUTH, data);
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, response.data.errors);
			});
	}

	@Action
	[Actions.FORGOT_PASSWORD](payload) {
		return ApiService.post("forgot_password", payload)
			.then(() => {
				this.context.commit(Mutations.SET_ERROR, {});
			})
			.catch(({ response }) => {
				this.context.commit(Mutations.SET_ERROR, response.data.errors);
			});
	}

	@Action
	[Actions.GET_GLOBAL_INFORMATIONS]() {
		const thisContenxt = this.context;
		if (JwtService.getToken()) {
			$.ajax({
				type: "POST",
				url: ApiService.getBaseUrl() + "/get_global_informations",
				async: false,
				headers: {
					Authorization: `Bearer ${JwtService.getToken()}`,
					Accept: "application/json",
				},
				data: {
					userAgent: navigator.userAgent,
				},
				success: function (data) {
					thisContenxt.commit(Mutations.SET_AUTH, data.auth);
					thisContenxt.commit(Mutations.SET_GLOBAL_INFORMATIONS, data.global_informations);

					//thisContenxt.dispatch(Actions.GET_EXTRA_GLOBAL_INFORMATIONS);
				},
				error: function (error) {
					if (error != null && error.status != null && error.status == 401) {
						thisContenxt.commit(Mutations.PURGE_AUTH);
					} else {
						thisContenxt.dispatch(Actions.GET_GLOBAL_INFORMATIONS);
					}
				},
			});
		} else {
			thisContenxt.commit(Mutations.PURGE_AUTH);
		}
	}
}
